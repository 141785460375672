export interface IDuration {
  years?: number;
  months?: number;
  days?: number;
  hours?: number;
  minutes?: number;
  seconds?: number;
}

export interface IDateTime {
  year?: number | string;
  month?: number | string;
  date?: number | string;
  hours?: number | string;
  minutes?: number | string;
  seconds?: number | string;
}

type DateKey = 'year' | 'month' | 'date' | 'hours' | 'minutes' | 'seconds';

export class DateTime {

  private readonly _date: Date;

  constructor(public readonly timeStmp: number) {
    this._date = new Date(timeStmp);
  }

  get year() {
    return this._date.getFullYear();
  }

  get month() {
    return this._date.getMonth() + 1;
  }

  get date() {
    return this._date.getDate();
  }

  get hours() {
    return this._date.getHours();
  }

  get minutes() {
    return this._date.getMinutes();
  }

  get seconds() {
    return this._date.getSeconds();
  }

  /**
   * 按照指定格式生成字符串
   * @param pattern 指定格式
   */
  format(pattern: string = defaultPattern) {
    return reduceFormat(pattern, (str, sign, i) => {
      const handle = signHandles[sign]
      if (handle) {
        return str.slice(0, i) + numToStr(handle.format(this), sign.length) + str.slice(i + sign.length);
      } else {
        return str;
      }
    });
  }

  toString() {
    return this.format();
  }

  get unix() {
    return Math.round(this._date.getTime() / 1000);
  }

  change(datetime: IDateTime): DateTime {
    datetime = Object.assign({
      year: this.year,
      month: this.month,
      date: this.date,
      hours: this.hours,
      minutes: this.minutes,
      seconds: this.seconds
    }, datetime);
    const date = createDate(Object.entries(datetime), new Date(), true);
    return new DateTime(date.getTime());
  }

  /**
   * 增加时间段
   * @param duration
   */
  add({ years = 0, months = 0, ... others }: IDuration): DateTime {
    let d: DateTime = this;
    if (years > 0) {
      d = d.change({ year: d.year + years });
    }
    if (months > 0) {
      d = d.change({ month: d.minutes + months });
    }
    return new DateTime(d.timeStmp + durationToTimeStmp(others));
  }

  /**
   * 减去一段时间
   * @param param0
   */
  subtract({ years = 0, months = 0, ... others }: IDuration): DateTime {
    let d: DateTime = this;
    if (years > 0) {
      d = d.change({ year: d.year - years });
    }
    if (months > 0) {
      d = d.change({ month: d.minutes - months });
    }
    return new DateTime(d.timeStmp + durationToTimeStmp(others));
  }

  startOf(key: DateKey): DateTime {
    if (key === "year") {
      return this.change({ month: 1, date: 1, hours: 0, minutes: 0, seconds: 0 });
    } else if (key === 'month') {
      return this.change({ date: 1, hours: 0, minutes: 0, seconds: 0 });
    } else if (key === 'date') {
      return this.change({ hours: 0, minutes: 0, seconds: 0 });
    } else if (key === 'hours') {
      return this.change({ minutes: 0, seconds: 0 });
    } else {
      return this.change({ seconds: 0 });
    }
  }

  endOf(key: DateKey): DateTime {
    if (key === 'year') {
      return this.change({ month: 12, date: lastDate(this.year, 12), hours: 23, minutes: 59, seconds: 59 });
    } else if (key === 'month') {
      return this.change({ date: lastDate(this.year, this.month), hours: 23, minutes: 59, seconds: 59 });
    } else if (key === 'date') {
      return this.change({ hours: 23, minutes: 59, seconds: 59 });
    } else if (key === 'hours') {
      return this.change({ minutes: 59, seconds: 59 });
    } else {
      return this.change({ seconds: 59 });
    }
  }
}

interface TimeHandle {
  name: string;
  format(d: DateTime): string;
  set(d: Date, value: number): void;
  sort: number;
}

const dFormatUnits = 'YYYY|MM|DD|HH|mm|ss';

const defaultPattern = 'YYYY-MM-DD HH:mm:ss';

const signHandles: { [key: string]: TimeHandle } = {
  'YYYY': {
    name: 'year',
    format: (d) => d.year.toString(),
    set: (d, value) => d.setFullYear(value),
    sort: 20
  },
  'MM': {
    name: 'month',
    format: (d: DateTime) => d.month.toString(),
    set: (d, value) => d.setMonth(value - 1),
    sort: 19
  },
  'DD': {
    name: 'date',
    format: (d: DateTime) => d.date.toString(),
    set: (d, value) => {
      d.setDate(Math.min(value, lastDate(d.getFullYear(), d.getMonth() + 1)));
    },
    sort: 18
  },
  'HH': {
    name: 'hours',
    format: (d: DateTime) => d.hours.toString(),
    set: (d, value) => d.setHours(value),
    sort: 17
  },
  'mm': {
    name: 'minutes',
    format: (d: DateTime) => d.minutes.toString(),
    set: (d, value) => d.setMinutes(value),
    sort: 16
  },
  'ss': {
    name: 'seconds',
    format: (d: DateTime) => d.seconds.toString(),
    set: (d, value) => d.setSeconds(value),
    sort: 15
  },
};

function numToStr(val: string, len: number) {
  const t = Math.max(len - val.length, 0);
  return new Array(t).fill(0).reduce((s) => s + '0', '') + val;
}

function durationToTimeStmp({ days = 0, hours = 0, minutes = 0, seconds = 0 }: IDuration): number {
  return (seconds + minutes * 60 + hours * 3600 + days * 24 * 3600) * 1000;
}

const day31 = [1,3,5,7,8,10,12];
const day30 = [4,6,9,11];

/**
 * 计算指定年月的最后一天是多少
 * @param year
 * @param month
 */
function lastDate(year: number, month: number): number {
  if (day31.indexOf(month) > -1) {
    return 31;
  } else if (day30.indexOf(month) > -1) {
    return 30;
  } else {
    return (year - 1900) % 4 === 0 ? 29 : 28;
  }
}

function reduceFormat(format: string, handle: (str: string, sign: string, index: number) => string): string {
  const regx = new RegExp(dFormatUnits);
  const match = regx.exec(format);
  if (match) {
    return reduceFormat(handle(format, match[0], match.index), handle);
  } else {
    return format;
  }
}

function getSignWeight(sign: string) {
  const handle = signHandles[sign];
  if (handle) {
    return handle.sort || 0;
  } else {
    return 0;
  }
}

function createDate(entry: Array<[string, string | number]>, base: Date = new Date(), byName = false): Date {
  return entry.sort(([a], [b]) => getSignWeight(a) > getSignWeight(b) ? -1 : 1)
    .reduce((date, [sign, val]) => {
      const handle = byName ? Object.values(signHandles).find((h) => h.name === sign) : signHandles[sign];
      if (handle) {
        handle.set(date, typeof val === 'number' ? val : parseInt(val, 10));
      }
      return date;
    }, base);
}

/**
 * 按照指定格式生成匹配指定的字符串, 以此生成新的 DateTime 对象
 * @param value 输入的字符串
 * @param pattern 指定日期格式
 * @returns {DateTime}  返回指定的时间对象, 如果输入字符没有与格式匹配, 会返回 `null`
 */
export function fromString(value: string, pattern = defaultPattern): DateTime | null {
  const signArr: string[] = [];
  const parseRegx = new RegExp(reduceFormat(pattern, (str, sign, i) => {
    signArr.push(sign);
    return str.slice(0, i) + `(\\d{${sign.length}})` + str.slice(i + sign.length);
  }));
  const match = parseRegx.exec(value);
  if (match) {
    const theDate = createDate(signArr.map((sign, i) => [sign, match[i + 1]]));
    return new DateTime(theDate.getTime());
  } else {
    return null;
  }
}

/**
 * 从原始 `Date` 对象生成 `DateTime`
 * @param date
 */
export function fromDate(date: Date) {
  return new DateTime(date.getTime());
}

/**
 * 返回当前时间
 */
export function now() {
  return fromDate(new Date());
}
