import { Component, SelectHTMLAttributes, createElement as _C } from 'react';

export interface ISelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
  options: { [key: string]: string };
}

export class Select extends Component<ISelectProps> {

  // selector: HTMLSelectElement | null = null;

  // container: HTMLLabelElement | null = null;

  // id = new Date().toString();

  componentDidMount() {
    // if (this.container) {
    //   this.container.addEventListener('click', (evt) => {
    //     if (this.selector) {
    //       this.selector.focus();
    //     }
    //   });
    // }
  }

  public render() {
    const { value, onChange, options, ...others } = this.props;
    return <div className="rn-selector">
      <select value={value} onChange={onChange} {...others}>
        {Object.entries(options).map(([key, val]) => <option key={key} value={key}>{val}</option>)}
      </select>
      <div className="mask">
        <span>{value && (options[value.toString()] || '')}</span>
        <div className="arrow" />
      </div>
    </div>
  }
}
