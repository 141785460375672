import { Component, InputHTMLAttributes, createElement as _C } from 'react';
import { fromString, fromDate } from './datetime';
import { Row } from './common';

const df = 'YYYY-MM-DD';

export interface IDatePickerProps {
  value: string;
  // options: { [key: string]: string };
  onChange: (val: string) => void;
}

export class DatePicker extends Component<IDatePickerProps> {

  input: HTMLInputElement | null = null;

  container: HTMLDivElement | null = null;

  componentDidMount() {
    // if (this.container) {
    //   this.container.addEventListener('click', (evt) => {
    //     if (this.input) {
    //       this.input.focus();
    //     }
    //   });
    // }
  }

  onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.valueAsDate ? fromDate(event.target.valueAsDate) : null;
    this.props.onChange && this.props.onChange(val ? val.format(df) : '');
  }

  public render() {
    const { value, onChange } = this.props;
    const theValue = typeof value != 'string' ? '' : value;
    const theDate = theValue ? fromString(theValue, df) : null;
    return <div className="date-picker" ref={(div) => this.container = div}>
      <input type="date" value={theDate ? theDate.format(df) : ''} onChange={this.onChange} ref={(sel) => this.input = sel} />
      <Row className="mask">
        <div className="selector">
          {theDate ? theDate.year : ''}年
            <div className="arrow" />
        </div>
        <div className="selector">
          {theDate ? theDate.month : ''}月
            <div className="arrow" />
        </div>
        <div className="selector">
          {theDate ? theDate.date : ''}日
            <div className="arrow" />
        </div>
      </Row>
    </div>
  }
}
